import React from "react";
import Layout from "../../components/Global/layout";
import ProductCarousel from "../../components/product/productCarousel";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { LocationMarkerIcon, DownloadIcon } from "@heroicons/react/solid";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";
import SEO from "../../components/seo/SEO";

const Scooters = () => {
  const data = useStaticQuery(graphql`
  {
    prismicProductPage(uid: {eq: "intercity-neo"}) {
      data {
        tagline
        ex_showroom_price
        product_name {
          text
        }
        catalog_link {
          url
        }
        color_grid {
          color
          grid01lg {
            gatsbyImageData
          }
          grid01sm {
            gatsbyImageData
          }
          grid01xs {
            gatsbyImageData
          }
          grid02sm {
            gatsbyImageData
          }
          grid02lg {
            gatsbyImageData
          }
          grid02xs {
            gatsbyImageData
          }
          grid03lg {
            gatsbyImageData
          }
          grid03sm {
            gatsbyImageData
          }
          grid04xs {
            gatsbyImageData
          }
          grid04sm {
            gatsbyImageData
          }
          grid04lg {
            gatsbyImageData
          }
          grid03xs {
            gatsbyImageData
          }
        }
        faq {
          question
          answer
        }
        features {
          feature_name {
            text
          }
          feature_image {
            gatsbyImageData
          }
        }
        product_highlight {
          title
          description
        }
        technical_specification {
          specification
          tech_spec_header {
            text
          }
        }
        use_case {
          description
          title
          image_xs {
            gatsbyImageData
          }
          image_sm {
            gatsbyImageData
          }
          image_md {
            gatsbyImageData
          }
          image_lg {
            gatsbyImageData
          }
        }
        user_testimonials {
          testimonial_photo {
            gatsbyImageData
          }
          testimonial_heading {
            text
          }
          testimonial_data
          testimonial_by
        }
      }
      uid
    }
  }
  `);

  return (
    <>
    <SEO />
      <Layout>
        <ProductCarousel />
        {/* XS CAROUSEL */}
        <div className="relative">
          <div className="absolute z-10 left-1/2 transform -translate-x-1/2 w-full top-4 md:top-6 lg:top-8">
            <ul className="flex flex-col items-center justify-center  space-y-2 lg:space-y-4">
              <h1 className="text-4xl md:text-5xl xl:text-6xl text-white font-medium">
                {data.prismicProductPage.data.product_name.text}
              </h1>
              <p className="font-lg md:text-lg lg:text-xl font-light text-gray-400">
                {data.prismicProductPage.data.tagline}
              </p>
            </ul>
          </div>
          <div className="m-3 aspect-w-2 aspect-h-3 bg-gray-800 rounded sm:hidden">
            <CarouselProvider
              className="w-full h-full"
              totalSlides={4}
              visibleSlides={1}
              naturalSlideHeight={3}
              naturalSlideWidth={2}
            >
              <Slider className="">
                <Slide
                  className="w-full h-full bg-cover bg-bottom bg-no-repeat"
                  index={0}
                >
                  <GatsbyImage
                    image={getImage(
                      data.prismicProductPage.data.color_grid[0].grid01xs
                    )}
                    className="w-full h-full"
                  />
                </Slide>
                <Slide
                  className="w-full h-full bg-cover bg-center bg-no-repeat"
                  index={1}
                >
                  <GatsbyImage
                    image={getImage(
                      data.prismicProductPage.data.color_grid[0].grid02xs
                    )}
                    className="w-full h-full"
                  />
                </Slide>
                <Slide
                  className="w-full h-full bg-cover bg-center bg-no-repeat"
                  index={2}
                >
                  <GatsbyImage
                    image={getImage(
                      data.prismicProductPage.data.color_grid[0].grid02xs
                    )}
                    className="w-full h-full"
                  />
                </Slide>
                <Slide
                  className="w-full h-full bg-cover bg-center bg-no-repeat"
                  index={3}
                >
                  <GatsbyImage
                    image={getImage(
                      data.prismicProductPage.data.color_grid[0].grid04xs
                    )}
                    className="w-full h-full"
                  />
                </Slide>
              </Slider>
            </CarouselProvider>
          </div>
          <div className="m-3 aspect-w-4 aspect-h-3 bg-gray-800 rounded hidden sm:block lg:hidden">
            <CarouselProvider
              className=""
              totalSlides={4}
              visibleSlides={1}
              naturalSlideHeight={3}
              naturalSlideWidth={4}
            >
              <Slider className="w-full">
                <Slide
                  className="w-full h-full bg-cover bg-top bg-no-repeat"
                  index={0}
                >
                  <GatsbyImage
                    image={getImage(
                      data.prismicProductPage.data.color_grid[0].grid01sm
                    )}
                    className="w-full h-full"
                  />
                </Slide>
                <Slide
                  className="w-full h-full bg-cover bg-center bg-no-repeat"
                  index={1}
                >
                  <GatsbyImage
                    image={getImage(
                      data.prismicProductPage.data.color_grid[0].grid02sm
                    )}
                    className="w-full h-full"
                  />
                </Slide>
                <Slide
                  className="w-full h-full bg-cover bg-center bg-no-repeat"
                  index={2}
                >
                  <GatsbyImage
                    image={getImage(
                      data.prismicProductPage.data.color_grid[0].grid03sm
                    )}
                    className="w-full h-full"
                  />
                </Slide>
                <Slide
                  className="w-full h-full bg-cover bg-center bg-no-repeat"
                  index={3}
                >
                  <GatsbyImage
                    image={getImage(
                      data.prismicProductPage.data.color_grid[0].grid04sm
                    )}
                    className="w-full h-full"
                  />
                </Slide>
              </Slider>
            </CarouselProvider>
          </div>
          <div className="m-3 aspect-w-16 aspect-h-7 bg-gray-800 rounded hidden lg:block">
            <CarouselProvider
              className=""
              totalSlides={4}
              visibleSlides={1}
              naturalSlideHeight={7}
              naturalSlideWidth={16}
            >
              <Slider className="w-full">
                <Slide
                  className="w-full h-full bg-cover bg-top bg-no-repeat"
                  index={0}
                >
                  <GatsbyImage
                    image={getImage(
                      data.prismicProductPage.data.color_grid[0].grid01lg
                    )}
                    className="w-full h-full"
                  />
                </Slide>
                <Slide
                  className="w-full h-full bg-cover bg-center bg-no-repeat"
                  index={1}
                >
                  <GatsbyImage
                    image={getImage(
                      data.prismicProductPage.data.color_grid[0].grid02lg
                    )}
                    className="w-full h-full"
                  />
                </Slide>
                <Slide
                  className="w-full h-full bg-cover bg-center bg-no-repeat"
                  index={2}
                >
                  <GatsbyImage
                    image={getImage(
                      data.prismicProductPage.data.color_grid[0].grid03lg
                    )}
                    className="w-full h-full"
                  />
                </Slide>
                <Slide
                  className="w-full h-full bg-cover bg-center bg-no-repeat"
                  index={3}
                >
                  <GatsbyImage
                    image={getImage(
                      data.prismicProductPage.data.color_grid[0].grid04lg
                    )}
                    className="w-full h-full"
                  />
                </Slide>
              </Slider>
            </CarouselProvider>
          </div>
        </div>

        <div className="flex flex-row items-center justify-center md:justify-between py-4 col-span-2 md:col-span-3 px-4">
          <ul className="invisible flex-row space-x-2">
            <li className="h-12 w-12 bg-blue-500 rounded-full"></li>
            <li className="h-12 w-12 bg-red-500 rounded-full"></li>
            <li className="h-12 w-12 bg-gray-800 rounded-full"></li>
          </ul>

          <div className="md:flex flex-row space-x-6 justify-center items-center p-4 hidden">
            <ul className="flex flex-col space-y-2">
              <li>
                <h2 className="text-4xl text-gray-300">
                  {data.prismicProductPage.data.ex_showroom_price}
                </h2>
              </li>
              <li>
                <p className="text-base text-gray-400">Ex-Showroom Goa</p>
              </li>
            </ul>
            <Link>
              <button className="button-icon">
                <LocationMarkerIcon className="h-6 text-gray-800" />
                <p className="text-base text-gray-800">Find a Store</p>
              </button>
            </Link>
          </div>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 p-1 gap-1 md:gap-3 md:px-3 lg:gap-4 lg:px-4 mx-auto">
          <div className="product-highlight-container">
            <h3 className="product-highlight-title">
              {data.prismicProductPage.data.product_highlight[0].title}
            </h3>
            <p className="product-highlight-description-big">
              {data.prismicProductPage.data.product_highlight[0].description}
            </p>
          </div>
          <div className="product-highlight-container">
            <h3 className="product-highlight-title">
              {data.prismicProductPage.data.product_highlight[1].title}
            </h3>
            <p className="product-highlight-description-big">
              {data.prismicProductPage.data.product_highlight[1].description}
            </p>
          </div>
          <div className="product-highlight-container">
            <h3 className="product-highlight-title">
              {data.prismicProductPage.data.product_highlight[2].title}
            </h3>
            <p className="product-highlight-description-big">
              {data.prismicProductPage.data.product_highlight[2].description}
            </p>
          </div>
          <div className="product-highlight-container">
            <h3 className="product-highlight-title">
              {data.prismicProductPage.data.product_highlight[3].title}
            </h3>
            <p className="product-highlight-description-big">
              {data.prismicProductPage.data.product_highlight[3].description}
            </p>
          </div>
          <div className="product-highlight-container">
            <h3 className="product-highlight-title">
              {data.prismicProductPage.data.product_highlight[4].title}
            </h3>
            <p className="product-highlight-description-small">
              {data.prismicProductPage.data.product_highlight[4].description}
            </p>
          </div>
          <div className="product-highlight-container">
            <h3 className="product-highlight-title">
              {data.prismicProductPage.data.product_highlight[5].title}
            </h3>
            <p className="product-highlight-description-small">
              {data.prismicProductPage.data.product_highlight[5].description}
            </p>
          </div>
        </div>
        <div className="flex flex-col space-y-6 justify-center items-center p-4 md:hidden">
          <ul className="flex flex-col space-y-2">
            <li>
              <h2 className="text-4xl text-gray-300">
                {data.prismicProductPage.data.ex_showroom_price}
              </h2>
            </li>
            <li>
              <p className="text-base text-gray-400">Ex-Showroom Goa</p>
            </li>
          </ul>
          <Link>
            <button className="button-icon">
              <LocationMarkerIcon className="h-6 text-gray-800" />
              <p className="text-base text-gray-800">Find a Store</p>
            </button>
          </Link>
        </div>
        {/* <section className="grid grid-cols-1 py-6 px-4 gap-4">
        {data.prismicProductPage.data.use_case.map((item) => (
          <div className="product-use-case-container">
            <GatsbyImage
              image={getImage(item.image_xs)}
              className="product-use-case-image"
            />
            <div className="product-use-case-text-container">
              <h4 className = "product-use-case-title">{item.title}</h4>
              <p className = "product-use-case-description">{item.description}</p>
            </div>
          </div>
        ))}
        
      </section> */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 p-2 sm:p-3 lg:p-4 gap-2 sm:gap-3 lg:gap-4 mx-auto">
      
        {data.prismicProductPage.data.features.map((item) => (
          <div className="col-span-1 flex flex-col justify-center items-center bg-gray-800 sm:m-0 md:m-0 rounded-md">
            <GatsbyImage
              image={getImage(item.feature_image)}
              className="w-full object-cover aspect-w-1 md:aspect-w-4 aspect-h-1 md:aspect-h-3 rounded-t-md"
            />
            <p className = "text-blue-500 bg-gray-700 w-full text-center rounded-b-md text-xl py-4">{item.feature_name.text}</p>
          </div>
        ))}
      </div>
      <section className="mt-8 grid bg-gray-800 grid-cols-[16px,5fr,4px,6fr,16px] md:grid-cols-[1fr,3fr,4px,3fr,1fr] gap-[1px] border-t-[1px] border-b-[1px] border-gray-800">
        <div className="py-2 bg-gray-900"></div>
        <div className="col-span-3 bg-gray-900">
          <div className="flex flex-col space-y-3 md:space-y-4 bg-gray-900 py-6 px-4 sm:px-8 md:px-12">
            <h2 className="text-2xl md:text-3xl leading-normal text-white">Technical Specification</h2>
          </div>
        </div>
        <div className="py-2 bg-gray-900"></div>
        {data.prismicProductPage.data.technical_specification.map((item) => (
          <>
            <div className="py-2 bg-gray-900"></div>
            <div className="py-3 pl-4 sm:pl-8 md:pl-12 bg-gray-900">
              <p className="text-sm sm:text-base md:text-lg text-gray-300 font-light">
                {item.tech_spec_header.text}
              </p>
            </div>
            <div className="py-2 bg-gray-900"></div>
            <div className="py-3 pl-4 sm:pl-8 md:pl-12 bg-gray-900">
              <p className="text-sm sm:text-base md:text-lg text-gray-400 font-light">
                {item.specification}
              </p>
            </div>
            <div className="py-2 bg-gray-900"></div>
          </>
        ))}
        <div className="py-2 bg-gray-900"></div>
        <div className="py-2 bg-gray-900 col-span-3 px-4 sm:px-8 md:px-12">
          <div className="py-4 flex flex-col sm:flex-row sm:justify-between">
            <p className="text-2xl text-gray-300 font-medium py-3 text-center sm:text-left">
              Need more Information?
            </p>
            <button className="button-icon">
              <p className="text-base text-gray-800">Download Catalog</p>
              <DownloadIcon className="h-6 text-gray-800" />
            </button>
          </div>
        </div>
        <div className="py-2 bg-gray-900"></div>
      </section>
      </Layout>
    </>
  );
};

export default Scooters;
